/* eslint-disable sort-keys */
import React, { useRef } from 'react';

const TrendingNFTs = () => {
	const sliderRef = useRef<HTMLDivElement>(null);

	const handleOnSlide = (direction: number) => {
		if (sliderRef.current) {
			const sliderList = sliderRef.current;
			const scrollAmount = sliderList.clientWidth * direction;
			sliderList.scrollBy({ behavior: 'smooth', left: scrollAmount });
		}
	};

	const slides = [
		{
			src: 'https://storage.googleapis.com/blobscriptions.appspot.com/user-uploads%2Fcm371exwp0001qep6f5tm8yge%2F1730971984304-jb2zdk0z3gekqf3tjnqd5cjs-eclipse_logo.jpg',
			link: 'https://app.blobscriptions.xyz/eclipse/collection/ECLIPSE-OG?marketplace=true'
		},
		{
			src: 'https://storage.googleapis.com/blobscriptions.appspot.com/user-uploads%2Fcm371exwp0001qep6f5tm8yge%2F1730995447787-soz8v7fiqlkj5stw6ep4d7ph-eclipse%20nft.png',
			link: 'https://app.blobscriptions.xyz/eclipse/collection/ECLIPSE-CM?marketplace=true'
		},
		{
			src: 'https://storage.googleapis.com/blobscriptions.appspot.com/user-uploads%2Fcm37itfjj00014bgvljechdd6%2F1730996889100-zgwsgzv6y5u54er1sonost4s-9.png',
			link: 'https://app.blobscriptions.xyz/eclipse/collection/PIX?marketplace=true'
		},
		{
			src: 'https://storage.googleapis.com/blobscriptions.appspot.com/user-uploads%2Fcm3buo5q1000112eyzpdldgah%2F1731260998092-rcpst76jik7ha13qk29spvkz-Screenshot_20241110_183336_Gallery.jpg',
			link: 'https://app.blobscriptions.xyz/eclipse/collection/MT?marketplace=true'
		},
		{
			src: 'https://storage.googleapis.com/blobscriptions.appspot.com/user-uploads%2Fcm3j4zmor0001og21m1bnw9ra%2F1731699323934-vw0cp2myjh8mqjn54yh5gyb2-Designer%20(32).jpeg',
			link: 'https://app.blobscriptions.xyz/eclipse/collection/ECLIPSE%20UNICORN?marketplace=true'
		}
	];

	return (
		<div className='w-full flex flex-col items-center md:p-20 sm:p-16 p-12 text-white mt-4'>
			<p className='font-monument text-center text-[32px] sm:text-[36px] md:text-[40px]'>Trending NFTs</p>

			<div className='w-[100vw] max-w-full relative'>
				<div
					className='overflow-x-auto [&::-webkit-scrollbar]:hidden'
					ref={sliderRef}
				>
					<div
						className='mt-12 slider-list'
						style={{ display: 'grid', gap: '36px', gridTemplateColumns: `repeat(${slides.length},1fr)` }}
					>
						<button
							onClick={() => handleOnSlide(1)}
							className='flex items-center justify-center bg-[#363338] sm:w-[75px] sm:h-[75px] w-[50px] h-[50px] rounded-full drop-shadow-[0px_6px_6.7px_rgba(0,0,0,0.57)] absolute -right-8 top-[50%] -translate-y-1/2 z-10'
						>
							<img
								src='Expand_right.png'
								className='sm:h-[32px] sm:w-[32px] h-[24px] w-[24px]'
							/>
						</button>
						{slides.map((item, i) => (
							<div
								className='md:w-[360px] md:h-[450px] sm:w-[300px] sm:h-[400px] w-[250px] h-[340px]'
								key={i}
							>
								<a
									href={item.link}
									target='_blank'
									rel='noreferrer'
								>
									<img
										src={item.src}
										className='rounded-lg'
									/>
								</a>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TrendingNFTs;
